import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';

declare var $: any;

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit, AfterViewInit, OnDestroy {


  private destroy$ = new Subject<void>();

  @Output() openPopup: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('requestCallBack') requestCallBack: any;
  isShown: boolean = false;
  apiLoaded: Observable<boolean> | undefined;

  markerPositions: google.maps.LatLngLiteral[] = [];
  center: { lat: number; lng: number; } | undefined;
  @ViewChild("gMap") gMap?: GoogleMap;
  @ViewChild(MapInfoWindow, {
    static: false
  }) infoWindow!: MapInfoWindow;
  mapTypeId = google.maps.MapTypeId.ROADMAP;
  mapTypeControl = false;
  disableDefaultUI: boolean = false;
  markers: any[] = [];
  show:boolean =false;


  styledMapType = new google.maps.StyledMapType(
    [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }

    ]
  );


  infoContent = ""

  ngAfterViewInit(): void {
   this.storeMaps();
  //  $('html, body').scrollTop($('#scroll').offset().top - 100);
  }

storeMaps(){
  if (this.storeInfoDetail.length > 0) {
    var bounds = new google.maps.LatLngBounds();
    for (let store of this.storeInfoDetail) {
      const pos = { lat: Number(store.lat), lng: Number(store.lng) };
      this.markers.push({
        position: pos,
        draggable: true,
        info: store,
        icon: './assets/img/dell-location.png'
      })
      bounds.extend(pos);
    }
    this.gMap?.fitBounds(bounds);
  }
}


  openInfo(marker: MapMarker, content: any) {

    this.infoContent = `
    <div class="header_info" >
   <a href="${content.formattedAddress }" queryParamsHandling="merge">
        ${content.displayName}
    </a>
    </div>
    <div class="store-info">
    <div class="store-time">
    <a href="tel:${content.phoneNumber}" class="call">Get In Touch</a>
     <p>${this.utils.getTodayStoreTimings(content.timings)}
    </p>
    </div>
    <div class="GMP_link">
    <a href="${content.gmapLink}" class="getDirection" target="_blank" >Get Direction</a>
    </div>
    </div>
    <div class="address_info">
     <p> ${content.address}</p>
    </div>
`;
    this.infoWindow.open(marker);
  }

keyEnter(event :any){
  if (event.keyCode === 13 ||event.keyCode === 32)
    this.toggleShow();
  if(event.keyCode === 32){
    event.preventDefault();
   setTimeout(()=>{
    $('html, body').scrollTop($('#viewOnMapBtn'));
   }, 100)
  }

}

  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.renderer.setProperty(this.document.querySelectorAll('#viewOnMapBtn')[0], "innerHTML", "View List");
      setTimeout(()=>{
        $("#scroll").focus();
      }, 400)


    } else {
      this.renderer.setProperty(this.document.querySelectorAll('#viewOnMapBtn')[0], "innerHTML", "View On Map");
    }
  }

  @Input() storeInfoDetail: any;
  page: number = 1;
  loadMore: boolean = false;
  loadMoreText: string = "Load More Stores";
  result!: boolean;
  MobStoreList: number = 0;
  distanceForStores :any;
  enquiryForm!: FormGroup;



  constructor(public app: AppComponent,
    private api: ApiService,
    public utils: UtilitiesService,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.document.body.classList.add('listingPage');
    if (this.storeInfoDetail.length > 0) {
      this.getTemplate();
      this.api.getStoreListWithInKm(this.app.searchType, this.app.latitude, this.app.longitude, this.app.distanceForTotalStore).pipe(takeUntil(this.destroy$)).subscribe((data) =>{
        this.distanceForStores = data[0];
      })
    }
    let onInit = this
    $('.sharewrap').on("click", function(e:any){
     let displayName =  $(e.target).data("displayName")
     let formattedAddress = $(e.target).data("formattedAddress")
     onInit.sharemobiel(displayName,formattedAddress)
    })
  }

  loadMoreStoresMob() {
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let record = this.app.record;
    let type = this.app.searchType;
    var page = this.page;
    this.loadMoreText = "Loading...  ";
    this.utils._loading.next(true)
    if (this.app.placeId.length > 0) {
      this.api.getStoreDetailsByPlaceId(tenant, this.app.placeId, distance, record, type, page).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        // $("#lastPage").remove()
        $(".slListing").slick("unslick");
        $('.slListing').css('visibility', "hidden");
        this.buildStoreCard(data);

        this.getTemplate()
        this.MobStoreList = this.MobStoreList + data.length
        setTimeout(() => {
          $('.slListing').slick('slickGoTo', this.MobStoreList);
          $('.slListing').css('visibility', "visible");
          this.utils._loading.next(false);

        }, 200)

       this.storeMaps();

      }, (err) => {
        this.utils._loading.next(false)
      });
    } else {
      this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        // $("#lastPage").remove()
        $(".slListing").slick("unslick");
        $('.slListing').css('visibility', "hidden");
       this.buildStoreCard(data);

        this.getTemplate()
        this.MobStoreList = this.MobStoreList + data.length;
        setTimeout(() => {
          $('.slListing').slick('slickGoTo', this.MobStoreList);
          $('.slListing').css('visibility', "visible");
          this.utils._loading.next(false)
        }, 200)
        this.storeMaps();
      }, (err) => {

        this.utils._loading.next(false)
      });
    }
  }






  loadMoreStores() {
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let record = this.app.record;
    let type = this.app.searchType;
    var page = this.page;


    this.loadMoreText = "Loading...  ";
    this.utils._loading.next(true)
    if (this.app.placeId.length > 0) {
      this.api.getStoreDetailsByPlaceId(tenant, this.app.placeId, distance, record, type, page).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        this.utils._loading.next(false);
        this.storeMaps();
        this.focusOnLatest(this.storeInfoDetail.length - data.length);
      }, (err) => {

        this.utils._loading.next(false)
      });
    } else {
      this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        this.utils._loading.next(false)
        this.storeMaps();
        this.focusOnLatest(this.storeInfoDetail.length - data.length);
      }, (err) => {

        this.utils._loading.next(false)
      });
    }
  }



  focusOnLatest = (focus: number) => {
    setTimeout(()=>{
      $(".lcHead a").eq(focus).focus();
    }, 400)

  }



  buildStoreCard(data :any[]){
    data.forEach(item => {
      const li = document.createElement('li');
      li.className = 'listingCard';

      const storeDistance = document.createElement('strong');
      storeDistance.className = 'storeDistance';

      const sdIcon = document.createElement('em');
      sdIcon.className = 'sdIcon flag';
      storeDistance.appendChild(sdIcon);

      const distanceSpan = document.createElement('span');
      distanceSpan.textContent = ` Distance - ${Number.parseFloat(item.distance).toFixed(2)} Km`;
      storeDistance.appendChild(distanceSpan);

      const shareWrap = document.createElement('span');
      shareWrap.className = 'sharewrap';
      shareWrap.dataset.displayName = item.displayName;
      shareWrap.dataset.formattedAddress = item.formattedAddress;
      shareWrap.textContent = 'Share';
      storeDistance.appendChild(shareWrap);

      li.appendChild(storeDistance);

      // Create the lcHead block
      const lcHead = document.createElement('div');
      lcHead.className = 'lcHead';

      const h2 = document.createElement('h2');
      const a = document.createElement('a');
      a.href = item.formattedAddress;
      a.textContent = item.displayName.split('-')[0];

      const small = document.createElement('small');
      small.textContent = item.displayName.split('-')[1];
      a.appendChild(small);

      const srOnlySpan = document.createElement('span');
      srOnlySpan.className = 'sr-only';
      srOnlySpan.textContent = `${item.displayName.split('-')[0]} - ${item.displayName.split('-')[1]}`;
      a.appendChild(srOnlySpan);

      h2.appendChild(a);
      lcHead.appendChild(h2);
      li.appendChild(lcHead);

      const storeAddress = document.createElement('div');
      storeAddress.className = 'storeAdress';
      const addressP = document.createElement('p');
      addressP.textContent = item.address;
      storeAddress.appendChild(addressP);
      li.appendChild(storeAddress);

      const sdListItems = document.createElement('ol');
      sdListItems.className = 'sdListItems';

      const liOpenFrom = document.createElement('li');
      const abbr = document.createElement('abbr');
      const spanOpenFrom = document.createElement('span');
      spanOpenFrom.textContent = 'Open From';
      abbr.appendChild(spanOpenFrom);

      const emOpenFrom = document.createElement('em');
      emOpenFrom.textContent = this.utils.getTodayStoreTimings(item.timings);
      abbr.appendChild(emOpenFrom);

      liOpenFrom.appendChild(abbr);
      sdListItems.appendChild(liOpenFrom);

      const liCall = document.createElement('li');
      const aCall = document.createElement('a');
      aCall.href = `tel:${item.phoneNumber}`;
      aCall.className = 'call';
      aCall.innerHTML = `<span class="sr-only">Contact for ${item.displayName.split('-')[1]} on</span>Call Store : ${item.phoneNumber}`;
      liCall.appendChild(aCall);
      sdListItems.appendChild(liCall);

      const liDirection = document.createElement('li');
      const aDirection = document.createElement('a');
      aDirection.href = item.gmapLink;
      aDirection.target = '_blank';
      aDirection.className = 'getDirection';
      aDirection.innerHTML = `Get Directions <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>`;
      liDirection.appendChild(aDirection);
      sdListItems.appendChild(liDirection);

      const liWebsite = document.createElement('li');
      const aWebsite = document.createElement('a');
      aWebsite.href = item.formattedAddress;
      aWebsite.className = 'websiteLink';
      aWebsite.innerHTML = `Store Website <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>`;
      liWebsite.appendChild(aWebsite);
      sdListItems.appendChild(liWebsite);

      li.appendChild(sdListItems);

      const getInTouchLink = document.createElement('a');
      getInTouchLink.href = `tel:${item.phoneNumber}`;
      getInTouchLink.className = 'mt-1 botn dds__button';
      getInTouchLink.onclick = () => this.app.getDataLayervalues(item, 'Get_in_touch_store');
      getInTouchLink.innerHTML = `Get In Touch <span class="sr-only"> for ${item.displayName.split('-')[1]}</span><span class="mobileNiHidemo"> - ${item.phoneNumber}</span>`;
      li.appendChild(getInTouchLink);

      const requestCallBackButton = document.createElement('button');
      requestCallBackButton.className = 'mt-3 botn dds__button dds__button--secondary';
      requestCallBackButton.onclick = () => this.triggerOpenPopup(item.id);
      requestCallBackButton.innerHTML = `Request Call Back <span class="sr-only"> for ${item.displayName.split('-')[1]}</span>`;
      li.appendChild(requestCallBackButton);

      const lastListingCard = document.querySelector('.listingCard:last-child');
      if(lastListingCard != null && lastListingCard.parentNode !=null) lastListingCard.parentNode.insertBefore(li, lastListingCard);
  });

  }



  getTemplate() {
    this.breakpointObserver.observe([
      "(max-width: 800px)"
    ]).pipe(takeUntil(this.destroy$)).subscribe((result: BreakpointState) => {
      this.result = result.matches
      if (result.matches) {
        this.slistingTemp();
      }
    })
  }

  slistingTemp() {
    $(document).ready(function () {
      $(".slListing").slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
        ],
      });
    })
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('listingPage');
    this.destroy$.next();
    this.destroy$.complete();
  }

  sharemobiel(displayName : string,address: string) {
    if (navigator.share) {
      navigator.share({
        title: displayName,
        url: this.app.YTOriginUrl + '/' +address
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      // shareDialog.classList.add('is-open');
    }
  };

  triggerOpenPopup(storeId:any) {
    this.openPopup.emit({ storeId: storeId, showPopup: true });
  }
  maxLengthTen(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 10)
    return false
  }

  maxLengthSix(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 6)
    return false
  }

  close(){
    this.show=false;
  }

}

// ${item.avg_rating != null ? '<span class="starRating">' + item.avg_rating + '</span>' : ""}
