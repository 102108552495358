import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "src/app/api.service";
import { AppComponent } from "src/app/app.component";
import { SharedInfoService } from "src/app/sharedInfo.service";
declare var $: any;

@Component({
  selector: "app-section",
  templateUrl: "./section.component.html",
  styleUrls: ["./section.component.scss"],

})
export class SectionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() sectionInput: any;
  mainSection: any;
  YTShown: boolean = false;
  banners: any = {};
  bannarVideos: any = [];
  offerBannerDesk: any;
  reviews: any[] = [];
  bannerSection: any[] = [];
  isAutoplay: boolean = true;
  toggleOffer: boolean = true;
  imageHeight: string = '338px';
  autoplay: boolean = true;

  private destroy$ = new Subject();
  constructor(
    private sharedInfoService: SharedInfoService,
    private breakpointObserver: BreakpointObserver,
    public app: AppComponent,
    private apiService: ApiService
  ) {

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.getTenant();
    this.getReviews();
  }

  imageClick(event: any) {
    this.imageHeight = event.imageHeight;
  }

  getTenant() {
    this.banners = [];
    this.apiService.getTenantId().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

      let content = JSON.parse(response.content.content);

      if (content.footer) this.apiService.footer.next(content.footer);
      if (content.products) this.apiService.products.next(content.products);
      if (content.faq) this.apiService.faq.next(content.faq);
      if (content.storeLocator) this.banners = content.storeLocator;
      this.sortingByOffers();
      this.sortingByOfferVideo();

      setTimeout(() => {
        this.reviewBanner();
      }, 800)
    })
  }

  sortingByOffers() {
    if (this.banners.offerBannerDesk) {
      this.bannerSection = this.banners.offerBannerDesk.sort(
        (a: any, b: any) => a.order - b.order
      ) as any[];
    }
  }

  sortingByOfferVideo() {
    this.bannarVideos = this.banners.videos
      .filter((t: any) => t.isDisplay === true)
      .sort((a: any, b: any) => a.order - b.order);
  }

  getReviews() {
    this.apiService.reviewsObservable.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data && data.length > 0) {
        this.reviews = data;
        this.slickReviews();

      }
    });
    this.sharedInfoService.currentSocialMedia.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.mainSection = data;
    });
  }

  videosSlider() {
    const offerSlider = $(".videoSlider");

    if (offerSlider.hasClass("slick-initialized")) {
      $(".videoSlider").slick("unslick");
    }

    if (!offerSlider) return;

    offerSlider.slick({
      centerMode: false,
      centerPadding: "0px",
      autoplay: this.autoplay,
      arrows: true,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      initialSlide: 0,
      speed: 500,
      easing: "ease",
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            centerMode: false,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 969,
          settings: {
            centerMode: false,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: false,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
      ],
    });

    const sliderElement = document.getElementById("videoSlider-705530888");

    let iframeFocused = false;
    let focusedElements: HTMLElement[] = [];

    function getIframeFocusableElements(iframe: HTMLIFrameElement): HTMLElement[] {
      try {
        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        return Array.from(
          iframeDoc?.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          ) || []
        ) as HTMLElement[];
      } catch {
        return [];
      }
    }

    sliderElement?.addEventListener("focusin", (event: FocusEvent) => {
      const activeElement = document.activeElement as HTMLElement;

      if (activeElement.tagName === "IFRAME") {
        iframeFocused = true;

        offerSlider.slick("slickSetOption", "draggable", false, true);
        offerSlider.slick("slickSetOption", "swipe", false, true);

        const iframe = activeElement as HTMLIFrameElement;
        focusedElements = getIframeFocusableElements(iframe);
      }
    });

    sliderElement?.addEventListener("focusout", (event: FocusEvent) => {
      const relatedTarget = event.relatedTarget as HTMLElement;

      if (iframeFocused && (!relatedTarget || !focusedElements.includes(relatedTarget))) {
        offerSlider.slick("slickSetOption", "draggable", true, true);
        offerSlider.slick("slickSetOption", "swipe", true, true);

        iframeFocused = false;
      }
    });

    offerSlider.on("beforeChange", (event: any, slick: any, currentSlide: any, nextSlide: any) => {
      if (iframeFocused) {
        event.preventDefault();
      } else {
        const iframe = document.getElementById(`videoPlayer-${currentSlide}`) as HTMLIFrameElement;
        iframe.src = "about:blank";
        this.updateVideoSrc(nextSlide);
      }
    });
    
  }


  toggleOfferPlay() {
    this.toggleOffer = !this.toggleOffer;
  }

  toggleAutoplay() {
    this.isAutoplay = !this.isAutoplay;
  }




  slickReviews() {
    setTimeout(() => {
      let $reviewSlider = $(".rsWrap").slick({
        infinite: true,
        autoplay: this.isAutoplay,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: "false",
      });
      $('#toggleAutoplay').on('click', (e: any) => {
        this.isAutoplay = !this.isAutoplay;
        $reviewSlider.slick('slickSetOption', 'autoplay', this.isAutoplay, true);
        $(e.target).find('i').toggleClass('fa-play fa-pause');
      });
      // Set the initial icon
      $('#toggleAutoplay').find('i').addClass('fa-pause');
    }, 0);
  }

  ngAfterViewInit(): void {
    $(".grList").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

            dots: true,
          },
        },
      ],
    });


    this.breakpointObserver
      .observe(["(max-width: 701px)"])
      .pipe(takeUntil(this.destroy$)).subscribe((result: BreakpointState) => {
        if (result.matches) {
          $(".mobielHomeSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
          $(".mobileSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }
      });
  }

  isPuase: boolean = true;
  playVideo(): void {
    this.isPuase = !this.isPuase;
    let youtubeIframe = document.getElementById('iframeVideo') as any;
    if (youtubeIframe != null) {
      let redPlayButton = youtubeIframe.contentWindow.document.getElementsByClassName('ytp-large-play-button')[0];
      redPlayButton.click();
    }
  }

  updateVideoSrc(index: number) {
    const iframe = document.getElementById(`videoPlayer-${index}`) as HTMLIFrameElement;
    const videoUrl = this.bannarVideos[index].url;
    iframe.src = videoUrl;
  }



  reviewBanner() {
    let $reviewSlider = $(".reviewSlider");
    if ($reviewSlider == undefined || $reviewSlider == null) return;

    $reviewSlider.slick({
      infinite: true,
      autoplay: this.toggleOffer,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            // dots: true,
          },
        },
      ],
    });

    $('#toggleOffer').on('click', (e: any) => {
      this.toggleOffer = !this.toggleOffer;
      $reviewSlider.slick('slickSetOption', 'autoplay', this.toggleOffer, true);
      $(e.target).find('i').toggleClass('fa-play fa-pause');
    })
    this.applyHeight();
    this.videosSlider();

  }


  @HostListener('window:resize', ['$event'])
  applyHeight(): void {
    const newWidth = window.innerWidth;
    if (newWidth > 1024 && newWidth < 1270) {
      const image = document.querySelector('.reviewSlider-image') as HTMLImageElement;
      if (image) {
        this.imageHeight = image.height + 'px';
      }
    } else {
      this.imageHeight = '';
    }
  }
}
